/* ====== WOW ====== */
new WOW().init();

/* ====== TO TOP ====== */
jQuery(document).ready(function($){
    var offset = 100,
    offset_opacity = 1200,
    scroll_top_duration = 700,
    $back_to_top = $('.cd-top');
    $(window).scroll(function(){
        ( $(this).scrollTop() > offset ) ? $back_to_top.addClass('cd-is-visible') : $back_to_top.removeClass('cd-is-visible cd-fade-out');
        if( $(this).scrollTop() > offset_opacity ) { 
            $back_to_top.addClass('cd-fade-out');
        }
    });
    $back_to_top.on('click', function(event){
        event.preventDefault();
        $('body,html').animate({
            scrollTop: 0
            }, scroll_top_duration
        );
    });
});

/* ====== FLEX SLIDER ====== */
$(".flexslider").flexslider({
    slideshow: true,
    animation:"fade",
    slideshowSpeed: 6000,
    animationLoop: true,
    pauseOnHover: false,
    controlNav: false, 
    keyboard: false
});

/* ====== Navigation ====== */
$(document).ready(function(){
    var scrollTop = 0;
    $(window).scroll(function(){
        scrollTop = $(window).scrollTop();
        if (scrollTop >= 150) {
            $('#top-menu').addClass('scrolled-nav');
        } else if (scrollTop < 150) {
            $('#top-menu').removeClass('scrolled-nav');
        } 
    }); 
});

/* ====== Products slider ====== */
$(".owl-carousel").owlCarousel({
    autoplay: !0,
    autoplayTimeout: 5500,
    margin: 0,
    nav: !1,
    loop: !0,
    responsive: {
        0: {
            items: 1
        },
        600: {
            items: 1
        },
        1000: {
            items: 1
        }
    }
})

/* ====== VALIDATION ====== */
//$('#btn-submit').click(function(){
//    var $captcha = $( '#recaptcha' ),
//	response = grecaptcha.getResponse();
//
//    if (response.length === 0) {
//	$( '.msg-error').text( "reCAPTCHA is mandatory" );
//	if( !$captcha.hasClass( "error" ) ){
//	    $captcha.addClass( "error" );
//	}
//    } else {
//	$( '.msg-error' ).text('');
//	$captcha.removeClass( "error" );
//    }
//});
//$("#regform").validate({
//    rules: {
//	fname: "required",
//	lname: "required",
//	email: {
//	    required: true,
//	    email: true
//	},
//	phone: "required",
//	message: "required"
//    },
//    messages: {
//	fname: "Please enter your first name",
//	lname: "Please enter your last name",
//	email: {
//	    required: "Please enter your email",
//	    email: "Email is not valid format"
//	},
//	phone: "Please enter your phone",
//	message: "Please enter your message"
//    }
//});